<script lang="ts" setup>
import type { Game } from "@/types";

const { open } = useAppModals();

const props = defineProps<{
	category?: string;
	title?: string;
	icon?: string;
	showAll?: boolean;
	hideFavorites?: boolean;
	games: Game[];
}>();

const { t } = useT();
const { handleClickExpand } = useGameSearch();

const emit = defineEmits<{ (event: "clearSearch"): void }>();

const handleNavToGames = () => {
	if (!props.category) {
		return;
	}

	if (props.category === "popular-games") {
		handleClickExpand(false);
	}

	navigateTo(`/issues/${props.category}/`);
};

const { handleOpenGame } = useOpenGame(open);
const { handleToggleToFavoriteClick } = useAddGameToFavorite({ t, toastTheme: "dark", toastPosition: "bottom-center" });

const handlePlayClick = (game: Game) => {
	if (!game.isOnlyForApp) {
		emit("clearSearch");
		handleOpenGame(game.slug);
	}
};
</script>
<template>
	<div class="result-games text-neutral-50">
		<div v-if="title" class="header">
			<AText v-if="title" class="title" :size="16" :modifiers="['bold']" as="h3">
				<ASvg v-if="icon" :name="icon" class="icon" />
				<span>{{ title }}</span>
			</AText>
			<AText
				v-if="showAll"
				:size="16"
				:modifiers="['underline', 'normal']"
				class="pointer text-neutral-200"
				@click="handleNavToGames"
			>
				{{ t("Show all") }}
			</AText>
		</div>

		<div class="box-games">
			<MGame
				v-for="(game, index) in games"
				:key="index"
				:game="game"
				class="box-game"
				:hideFavorites="hideFavorites"
				@toggle-favorite="handleToggleToFavoriteClick(game)"
				@play="handlePlayClick(game)"
			/>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.result-games {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 296px;
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-height: 24px;
}

.box-games {
	display: grid;
	grid-template-columns: repeat(3, 94px);
	grid-gap: 8px;
}

.title {
	display: inline-flex;
	align-items: center;
	gap: 4px;
}

.pointer {
	cursor: pointer;
}

.icon {
	font-size: 24px;
}
</style>
